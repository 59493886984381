import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "UserUseKeyLogView"
};
import Apis_Key from "@/apis/Apis_Key";
import { onMounted, ref } from "vue";
import TableXsr from "@/components/Table/Table-User";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
export default {
  __name: 'UserUseKeyLog',
  props: ['UserName'],
  setup(__props) {
    const props = __props;
    const LogArr = ref([]);
    const GetUserUseKey = username => {
      Apis_Key.getUserUseRecKey(username).then(res => {
        var _res$data;
        if (!Apis_Key.checkResCode(res)) {
          return;
        }
        console.log('use', res);
        LogArr.value.length = 0;
        let arr = (_res$data = res.data) !== null && _res$data !== void 0 ? _res$data : [];
        for (let arrKey in arr) {
          let {
            key,
            money
          } = arr[arrKey];
          LogArr.value.push({
            id: key,
            username: key,
            label: money
          });
        }
      });
    };
    onMounted(() => {
      GetUserUseKey(props.UserName);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableXsr), {
        ViewData: LogArr.value,
        HeaderTitle: "充值记录",
        onTableEvent: _cache[0] || (_cache[0] = () => _unref(VantMsgLib).notify(4, '暂不支持'))
      }, null, 8, ["ViewData"])]);
    };
  }
};