import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, ref, watch } from "vue";
export default {
  __name: 'Table-Pagination',
  props: ['TableData'],
  emits: ['UpdateTableData'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const nowPage = ref(1); //当前页数
    const pageSize = ref(20); //每页显示数量
    //总记录数
    const total = computed(() => {
      // console.log('total',props.TableData.length)
      return props.TableData.length;
    });
    //总页数
    const pageCount = computed(() => {
      let size = total.value / pageSize.value;
      size = Math.ceil(size);
      return size;
    });
    //页码改变时触发
    const change = () => {
      let max = nowPage.value * pageSize.value;
      let min = max - pageSize.value;
      let arr = props.TableData.slice(min, max);
      emit('UpdateTableData', arr);
    };
    watch(() => props.TableData, () => {
      nowPage.value = 1;
      change();
    }, {
      deep: true
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_pagination = _resolveComponent("van-pagination");
      return _openBlock(), _createBlock(_component_van_pagination, {
        modelValue: nowPage.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => nowPage.value = $event),
        "total-items": _unref(total),
        "page-count": _unref(pageCount),
        onChange: change
      }, {
        "prev-text": _withCtx(() => [_createVNode(_component_van_icon, {
          name: "arrow-left"
        })]),
        "next-text": _withCtx(() => [_createVNode(_component_van_icon, {
          name: "arrow"
        })]),
        page: _withCtx(({
          text
        }) => [_createTextVNode(_toDisplayString(text), 1)]),
        _: 1
      }, 8, ["modelValue", "total-items", "page-count"]);
    };
  }
};