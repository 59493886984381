import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e9e4249"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TableMain"
};
const _hoisted_2 = {
  class: "PageView"
};
import { ref, toRaw } from "vue";
import TablePagination from "@/components/Table/Table/Table-Pagination";
import TableHeader from "@/components/Table/Table/Table-Header";
import Common_Router from "@/utils/Common/Common_Router";
import TableTable from "@/components/Table/Table/Table-Table";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'Table-User',
  props: ['ViewData', 'HeaderTitle'],
  emits: ['TableEvent'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    /**
     * TableData:
     * [数组对象]每个对象包含(用户名[username]与用户id[id])
     */
    const LangLib = new LangCom('Table', 'TableUser');
    // const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);

    /**
     * ViewData 全部待显示的数据
     * HeaderTitle 头部标题
     */

    //[分页组件]传来表格数据变动
    const TableData = ref([]);
    const UpdateTableData = options => {
      TableData.value = options;
    };

    //[头部组件]执行动作
    const refsTable = ref();
    const HeaderOnSelect = msg => {
      switch (msg.type) {
        case 1:
          //全选
          refsTable.value.checkboxGroup.toggleAll(true);
          break;
        case 2:
          //取消全选
          refsTable.value.checkboxGroup.toggleAll(false);
          break;
        case 3:
          //加入待操作的用户列表
          if (refsTable.value.checked.length) {
            SendTableEvent(2, toRaw(refsTable.value.checked));
          } else {
            VantMsgLib.notify(4, GetLangSetup('HeaderOnSelect.tips'));
          }
          break;
        case 4:
          //刷新页面
          Common_Router.RefreshPage();
          break;
        case 5:
          //呼出工具栏
          SendTableEvent(1);
          break;
        case 6:
          //点击了放大镜图标
          SendTableEvent(4);
          break;
      }
    };

    /**
     * 向父组件传递事件
     * @param type [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.点击了放大镜图标[筛选/搜索]
     * @param data 数据
     * @param attach 附带消息
     * @constructor
     */
    const SendTableEvent = (type, data = null, attach = null) => {
      let res = {
        type,
        data,
        attach
      };
      emits('TableEvent', res);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableHeader), {
        onHeaderOnSelect: HeaderOnSelect,
        HeaderTitle: __props.HeaderTitle
      }, null, 8, ["HeaderTitle"]), _createVNode(_unref(TableTable), {
        ref_key: "refsTable",
        ref: refsTable,
        TableData: TableData.value,
        onClickTable: _cache[0] || (_cache[0] = options => SendTableEvent(3, options.item, options.index))
      }, null, 8, ["TableData"]), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(TablePagination), {
        TableData: props.ViewData,
        onUpdateTableData: UpdateTableData
      }, null, 8, ["TableData"])])]);
    };
  }
};