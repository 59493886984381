import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c62b9e76"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TableView"
};
import { ref, toRaw } from "vue";
export default {
  __name: 'Table-Table',
  props: ['TableData'],
  emits: ['ClickTable'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    const checked = ref([]);
    const checkboxGroup = ref();
    const checkboxRefs = ref([]);

    //表项被单击
    const toggle = (index, item) => {
      emits('ClickTable', {
        index,
        item: toRaw(item)
      });
    };
    expose({
      checked,
      checkboxGroup,
      checkboxRefs
    });
    return (_ctx, _cache) => {
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_checkbox_group, {
        modelValue: checked.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => checked.value = $event),
        style: {
          "overflow-x": "scroll",
          "height": "100%"
        },
        ref_key: "checkboxGroup",
        ref: checkboxGroup
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.TableData, (item, index) => {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: item.id,
              title: `${item.username}`,
              label: item.label || null,
              clickable: "",
              onClick: $event => toggle(index, item)
            }, {
              "right-icon": _withCtx(() => [_createVNode(_component_van_checkbox, {
                name: item,
                ref_for: true,
                ref: el => checkboxRefs.value[index] = el,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["name"])]),
              _: 2
            }, 1032, ["title", "label", "onClick"]);
          }), 128))]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};