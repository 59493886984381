import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
import { ref, inject } from "vue";
import Common_Router from "@/utils/Common/Common_Router";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'Table-Header',
  props: ['HeaderTitle'],
  emits: ['HeaderOnSelect'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const LangLib = new LangCom('Table', 'TableHeader');
    //const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);

    /**
     * [最顶级组件]父组件传来参数(provide)
     * 是否显示放大镜图标[筛选/搜索]
     * @type {boolean}
     */
    const SearchIcon = inject('SearchIcon', false);
    const actions = ref([{
      text: GetLangSetup('actions.success'),
      icon: 'success'
    }, {
      text: GetLangSetup('actions.cross'),
      icon: 'cross'
    }, {
      text: GetLangSetup('actions.plus'),
      icon: 'plus'
    }, {
      text: GetLangSetup('actions.circle'),
      icon: 'circle'
    }, {
      text: GetLangSetup('actions.apps_o'),
      icon: 'apps-o'
    }]);
    const onSelect = action => {
      let msg = {
        type: 1,
        action
      };
      switch (action.icon) {
        case 'success':
          //全选
          msg.type = 1;
          break;
        case 'cross':
          //取消全选
          msg.type = 2;
          break;
        case 'plus':
          msg.type = 3;
          break;
        case 'circle':
          //刷新
          msg.type = 4;
          break;
        case 'apps-o':
          //呼出工具栏
          msg.type = 5;
          break;
      }
      emits('HeaderOnSelect', msg);
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_popover = _resolveComponent("van-popover");
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        title: props.HeaderTitle,
        "left-arrow": "",
        onClickLeft: _cache[1] || (_cache[1] = () => _unref(Common_Router).Back())
      }, {
        right: _withCtx(() => [_unref(SearchIcon) ? (_openBlock(), _createBlock(_component_van_icon, {
          key: 0,
          name: "search",
          size: "25",
          style: {
            "margin-right": "10px"
          },
          onClick: _cache[0] || (_cache[0] = () => emits('HeaderOnSelect', {
            type: 6,
            action: 'search'
          }))
        })) : _createCommentVNode("", true), _createVNode(_component_van_popover, {
          actions: actions.value,
          placement: "left-start",
          onSelect: onSelect
        }, {
          reference: _withCtx(() => [_createVNode(_component_van_icon, {
            name: "flower-o",
            size: "25"
          })]),
          _: 1
        }, 8, ["actions"])]),
        _: 1
      }, 8, ["title"]);
    };
  }
};