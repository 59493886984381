import AxiosLib from "@/apis/Axios/AxiosLib";
import ApisLang from "@/locales/Apis/ApisLang";

const url = 'AgentApi/Key.';
const apis = {
    ...AxiosLib,

    /**
     * 生成地址
     * @param page [页面名字]子模块名
     * @param name 功能名
     * @return {string}
     * 返回完整Url
     */
    MakeUrl(page,name) {
        return `${url}${page}/${name}`;
    },

    //查询用户充值码使用记录
    getUserUseRecKey(username){
        return AxiosLib.post(this.MakeUrl('KeyData','getUserUseRecKey'),{username},true,false);
    },

    //获取卡密数量
    getAgentKeyCount(type,status,agent){
        return AxiosLib.post(this.MakeUrl('KeyData','getAgentKeyCount'),{type,status,agent},true,false);
    },

    //获取卡密列表
    getAgentKeyList(type,status,agent,listRows,page){
        return AxiosLib.post(
            this.MakeUrl('KeyData','getAgentKeyList')
            ,{type,status,agent,listRows,page},
            true,false
        );
    },

    //生成卡密
    makeKey(makeNum,nickname,money,type,){
        let fieldCodeLang = ApisLang('Key','makeKey');
        return AxiosLib.post(
            this.MakeUrl('Make','makeKey')
            ,{makeNum,nickname,money,type,},
            true,true,
            fieldCodeLang
        );
    },

    /**
     * 批量迁移卡密[自己->下级代理]
     * @param keyList 卡密数组
     * @param object 下级代理商账号
     * @param keyType [提交类型] 1=卡密 2=条形码
     * @return {*}
     */
    batchMoveKey(keyList,object,keyType){
        let fieldCodeLang = ApisLang('Key','batchMoveKey');
        return AxiosLib.post(
            this.MakeUrl('keyMove','batchMoveKey')
            ,{keyList,object,keyType},
            true,true,
            fieldCodeLang
        );
    },

    //批量回迁卡密[下级代理商->自己]
    batchMoveKeyBySubordinate(keyList){
        //[fieldCodeLang]错误代码与(batchMoveKey)一致,所以无需单独设置语言字段
        let fieldCodeLang = ApisLang('Key','batchMoveKey');
        return AxiosLib.post(
            this.MakeUrl('keyMove','batchMoveKeyBySubordinate')
            ,{keyList},
            true,true,
            fieldCodeLang
        );
    },

    //回收卡密[自己卡密]
    batchRecoveryKey(keyList,keyType){
        //[fieldCodeLang]错误代码与(batchMoveKey)一致,所以无需单独设置语言字段
        let fieldCodeLang = ApisLang('Key','batchRecoveryKey');
        return AxiosLib.post(
            this.MakeUrl('recovery','batchRecoveryKey')
            ,{keyList,keyType},
            true,true,
            fieldCodeLang
        );
    },

    //查询批量生成卡密记录
    getLog(){
        return AxiosLib.post(this.MakeUrl('Make','getLog'),{},true,false);
    },
    //下载某个卡密记录文件
    getFile(file){
        return AxiosLib.post(this.MakeUrl('Make','getFile'),{file},true,false);
    },

    //筛选卡密
    filterKey(PostData){
        return AxiosLib.post(
            this.MakeUrl('KeyData','filterKey')
            ,PostData,
            true,false,
        );
    },

};
export default apis;